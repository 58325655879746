@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital@1&family=Roboto');

.logo {
  font-family: 'Patua One', sans-serif;
  font-weight: 400;
}

.landing {
  font-size: 72px;
}


.middle-center {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  margin: auto auto;
  position: absolute;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/


/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
